import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import SpeakerSingle from "../../src/components/speaker-single";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SpeakerSingle name="Soledad González" profession="Feminista, politóloga, integrante de Cotidiano Mujer (Uruguay)" presentation="¿Por qué las instituciones españolas violan sistemáticamente los derechos humanos de niños, niñas y adolescentes víctimas de abuso sexual infantil entregándoselos a sus abusadores?" youtubeid="JFxwuiWDmC4" hasspeach="true" skipTo={{
      h: 0,
      m: 16,
      s: 39
    }} mdxType="SpeakerSingle" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      